import { createApiRef, FetchApi } from '@backstage/core-plugin-api';
import {
  CreateSystemPayload,
  CreateSystemResponse,
  CreateSystemSuccessResponse,
  RegisterComponentPayload,
  RegisterComponentResponse,
} from '@internal/plugin-onboarding-backend';
import { Config } from '@backstage/config';
import { DecathlonSSOAuthProvider } from '@internal/plugin-decathlon-sso';
import { CatalogApi } from '@backstage/plugin-catalog-react';
import {
  ComponentEntity,
  Entity,
  GroupEntity,
  SystemEntity,
} from '@backstage/catalog-model';

export const onboardingApiRef = createApiRef<OnboardingClient>({
  id: 'plugin.dpac.api',
});

export type BusinessCriticalityLevel =
  | 'Mission critical'
  | 'Administrative service'
  | 'Business critical'
  | 'Business operational';

export interface System extends SystemEntity {
  spec: {
    owner: string;
    budget?: number;
    businessCriticalityScore?: number;
    businessCriticalityLevel?: BusinessCriticalityLevel;
    domainName: string;
    subdomainName?: string;
  };
}

export class OnboardingClient {
  private readonly fetchApi: FetchApi;
  private readonly config: Config;
  private readonly decathlonSSOAuthProvider: DecathlonSSOAuthProvider;
  private readonly catalogApi: CatalogApi;

  constructor(options: {
    fetchApi: FetchApi;
    config: Config;
    decathlonSSOAuthProvider: DecathlonSSOAuthProvider;
    catalogApi: CatalogApi;
  }) {
    this.fetchApi = options.fetchApi;
    this.config = options.config;
    this.decathlonSSOAuthProvider = options.decathlonSSOAuthProvider;
    this.catalogApi = options.catalogApi;
  }

  async createSystem(
    system: CreateSystemPayload,
  ): Promise<CreateSystemSuccessResponse['response'] | Error> {
    const backendUrl = this.config.getString('backend.baseUrl');
    const fedToken = await this.decathlonSSOAuthProvider.getAccessToken();

    const response = await this.fetchApi.fetch(
      `${backendUrl}/api/onboarding/system`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-fed-authorization': `Bearer ${fedToken}`,
        },
        body: JSON.stringify(system),
      },
    );

    const responseBody: CreateSystemResponse = await response.json();
    if (response.ok && responseBody.result === 'success') {
      return responseBody.response;
    }

    return new Error(JSON.stringify(responseBody));
  }

  async addComponent(
    component: RegisterComponentPayload,
  ): Promise<RegisterComponentResponse> {
    const backendUrl = this.config.getString('backend.baseUrl');

    const response = await this.fetchApi.fetch(
      `${backendUrl}/api/onboarding/components`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(component),
      },
    );

    const responseBody = await response.json();
    if (response.ok) {
      return responseBody;
    }

    throw responseBody;
  }

  async initIdpBack(): Promise<Error | true> {
    let response = await this.purge();
    if (!response.ok) {
      return new Error(JSON.stringify(await response.json()));
    }

    response = await this.initDomains();
    if (!response.ok) {
      return new Error(JSON.stringify(await response.json()));
    }

    response = await this.initSubDomains();
    if (!response.ok) {
      return new Error(JSON.stringify(await response.json()));
    }

    response = await this.initDomainSubDomainLinks();
    if (!response.ok) {
      return new Error(JSON.stringify(await response.json()));
    }

    response = await this.initSupportGroups();
    if (!response.ok) {
      return new Error(JSON.stringify(await response.json()));
    }

    response = await this.initSystems();
    if (!response.ok) {
      return new Error(JSON.stringify(await response.json()));
    }

    response = await this.initComponents();
    if (!response.ok) {
      return new Error(JSON.stringify(await response.json()));
    }

    response = await this.initDependencies();
    if (!response.ok) {
      return new Error(JSON.stringify(await response.json()));
    }

    response = await this.initDpac();
    if (!response.ok) {
      return new Error(JSON.stringify(await response.json()));
    }

    return true;
  }

  private async purge() {
    const fedToken = await this.decathlonSSOAuthProvider.getAccessToken();
    return await this.fetchApi.fetch(
      `${this.config.getString(
        'backend.baseUrl',
      )}/api/proxy/idp-back/api/v1/init/purge`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${fedToken}`,
        },
      },
    );
  }

  private async initDomains() {
    return this.callIdpBackWithBatch(
      '/api/v1/init/domains',
      await this.getGroupsByType('domain'),
    );
  }

  private async initSubDomains() {
    return this.callIdpBackWithBatch(
      '/api/v1/init/sub_domains',
      await this.getGroupsByType('subdomain'),
    );
  }

  private async initDomainSubDomainLinks() {
    return this.callIdpBackWithBatch(
      '/api/v1/init/domain_sub_domain_links',
      await this.getGroupsByType('domain'),
    );
  }

  private async initSupportGroups() {
    return this.callIdpBackWithBatch(
      '/api/v1/init/support_groups',
      await this.getGroupsByType('team'),
    );
  }

  private async initSystems() {
    return this.callIdpBackWithBatch(
      '/api/v1/init/systems',
      await this.getAllSystems(),
      1,
    );
  }

  private async initComponents() {
    return this.callIdpBackWithBatch(
      '/api/v1/init/components',
      await this.getAllComponents(),
    );
  }

  private async initDependencies() {
    return this.callIdpBackWithBatch(
      '/api/v1/init/dependencies',
      await this.getAllComponents(),
    );
  }

  private async initDpac() {
    const fedToken = await this.decathlonSSOAuthProvider.getAccessToken();
    return await this.fetchApi.fetch(
      `${this.config.getString(
        'backend.baseUrl',
      )}/api/proxy/idp-back/api/v1/init/dpac`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${fedToken}`,
        },
      },
    );
  }

  private async callIdpBackWithBatch(
    endpoint: string,
    entityList: Entity[],
    step: number = 50,
  ) {
    let response = await this.callIdpBackWithRetry(
      endpoint,
      entityList.slice(0, step),
    );
    for (let i = step; i < entityList.length; i = i + step) {
      response = await this.callIdpBackWithRetry(
        endpoint,
        entityList.slice(i, i + step),
      );
      if (!response.ok) {
        return response;
      }
    }
    return response;
  }

  private async callIdpBackWithRetry(endpoint: string, entityList: Entity[]) {
    const maxTry = 3;
    let tryCount = 0;
    let response = await this.callIdpBack(endpoint, entityList);
    while (tryCount < maxTry && !response.ok) {
      response = await this.callIdpBack(endpoint, entityList);
      tryCount = tryCount + 1;
    }
    return response;
  }

  private async callIdpBack(endpoint: string, entityList: Entity[]) {
    const fedToken = await this.decathlonSSOAuthProvider.getAccessToken();

    return await this.fetchApi.fetch(
      `${this.config.getString(
        'backend.baseUrl',
      )}/api/proxy/idp-back${endpoint}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${fedToken}`,
        },
        body: JSON.stringify(entityList),
      },
    );
  }

  async getGroupsByType(
    type: 'domain' | 'subdomain' | 'team',
  ): Promise<GroupEntity[]> {
    const complementarySystemInformation = await this.catalogApi.getEntities({
      filter: {
        kind: ['Group'],
        'spec.type': type,
      },
    });

    return complementarySystemInformation.items as GroupEntity[];
  }

  private async getAllSystems(): Promise<System[]> {
    const entities = await this.catalogApi.getEntities({
      filter: {
        kind: ['System'],
      },
    });

    return entities.items as System[];
  }

  private async getAllComponents(): Promise<ComponentEntity[]> {
    const componentsResponse = await this.catalogApi.getEntities({
      filter: {
        kind: ['Component'],
      },
    });

    return componentsResponse.items as ComponentEntity[];
  }
}
